const nameKeys = {
  token: "ACCESS-TOKEN",
  userInfo: "USER-INFO",
  userRoutes: "USER-ROUTES",
  timezone: "USER-TIMEZONE",
  hasSecurityMail: "HAS-SECURITY-MAIL",
};

const extensionKeys = {
  confLang: "CONF-LANG",
};

const headerKeys = {
  authorization: "Authorization",
  bearer: "Bearer",
  customCulture: "custom-culture",
};

const consignmentKeys = {
  createConsignmentOrderData: "CREATE-CONSIGNMENT-ORDERDATA",
  createConsignmentOrderAreaCode: "CREATE-CONSIGNMENT-AREA-CODE",
};

const outPlanKeys = {
  createOutPlanData: "CREATE-OUT-PLAN-DATA",
};

const allotPlanKeys = {
  setBoxPageData: "SET-BOX-PAGE-DATA",
  confirmPageData: "CONFIRM-PAGE-DATA",
};

const transitOutPlanKeys = {
  createOutPlanData: "TRANSIT-CREATE-OUT-PLAN-DATA",
};

export {
  nameKeys,
  headerKeys,
  consignmentKeys,
  outPlanKeys,
  allotPlanKeys,
  extensionKeys,
  transitOutPlanKeys
};
