<template>
  <a-layout id="app-layout" :class="[isMobile && 'layout-mobile', appTheme]">
     <div
      v-if="isMobile && !collapsed"
      class="layout-mobile-mask"
      @click="closeSideBar"
    />
    <a-layout-sider
        v-if="!isHeaderMenu"
        :collapsed="collapsed" :class="[isLightSider && 'ant-layout-sider-light']">
          <Logo></Logo>
          <Menu></Menu>
    </a-layout-sider> 
    <a-layout>
      <a-layout-header :class="['layout-header', hasTabs ?'has-tabs':'']" >
        <PageHeader></PageHeader>
        <Tabs v-if="hasTabs"/>
      </a-layout-header>
       <a-layout-content
        id="layout-content"
        
      >
        <Content :style="{ overflow: fixedHeader ? 'auto' : '' }"></Content>
      </a-layout-content>
   
    </a-layout>
        <CEnvTips />
  </a-layout>
</template>
<script>
import {defineComponent, computed, onMounted, toRefs, reactive} from "vue"
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons-vue';

import Content from "./pritails/content/Index";
import PageHeader from "./pritails/header/Index"
import Menu from "./pritails/menu/Index"
import Tabs  from './pritails/tabs/index.vue'
import Logo from './pritails/logo/index.vue'
import CEnvTips from "@/views/components/CEnvTips.vue";
import { Layout, Modal } from 'ant-design-vue';
import { startVersionCompare } from "@/versionHandler/index.js"
import { useI18n } from "vue-i18n/index";
import { nameKeys } from "@/consts/index.js";

export default defineComponent({
  name:"LayoutIndex",
  components: {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    Content,
    PageHeader,
    Menu,
    Tabs,
    Logo,
    ALayout: Layout,
    ALayoutSider: Layout.Sider,
    ALayoutHeader: Layout.Header,
    ALayoutContent: Layout.Content,
        CEnvTips,
  },

  setup() {
    const { getters, commit } = useStore();
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const data = reactive({
        fixedHeader:false,
        hasTabs: true
    });
    const layout = computed(()=>getters.layout);
    const collapsed = computed(() => getters.collapsed);
    const isMobile = computed(() => getters.isMobile);
    const isHeaderMenu = computed(() => getters.layout === "layout-head" && !getters.isMobile);
    const closeSideBar = ()=>{
      commit("app/TOGGLE_COLLAPSED");
    }
    const appTheme = computed(()=>getters.theme);
    const isLightSider = computed(()=>getters.theme === "theme-light");
    const onResize = ()=>{
      const {width} = document.body.getBoundingClientRect();
      const isLayoutMobile = width !== 0 && width - 1 < 576;
      commit("app/UPDATE_ISMOBILE", isLayoutMobile);

      if (isLayoutMobile) {
        setTimeout(() => {
          if(getters.isMobile && !getters.collapsed){
            commit("app/TOGGLE_COLLAPSED");
          }
        }, 500);
      }
    };
    onResize();
    window.addEventListener("resize",onResize);

    // 适配
    let timer = null;
    const baseWidth = 1920;
    const minBaseWidth = 1000;
    let doZoom = function () {      
      // var clientWidth = window.innerWidth;
      // var clientHeight = window.innerHeight;
      var clientWidth = window.screen.width;
      var clientHeight = window.screen.height;
      var isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
      var ht = document.documentElement;
      if (clientWidth >= minBaseWidth && clientWidth <= baseWidth) {
        let rate = (clientWidth / baseWidth || 1).toFixed(6);
        if (!isFirefox) {
          ht.style['zoom'] = rate;
        } else {
          ht.style['-moz-transform'] = `scale(${rate})`;
          ht.style['-moz-transform-origin'] = `top left`;
          ht.style['width'] = baseWidth + 'px';
          ht.style['height'] = clientHeight / (rate == 0 ? 1 : rate) + 'px';
        }
      } else {
        if (isFirefox) {
          ht.style['-moz-transform'] = '';
          ht.style['-moz-transform-origin'] = '';
          ht.style['width'] = '100%';
          ht.style['height'] = 'auto';
        } else {
          ht.style['zoom'] = 1;
        }
      }
    };

    const doZoomOnResize = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        doZoom();
      }, 500);
    };

    doZoom();
    window.addEventListener('resize', doZoomOnResize);

    const checkSecurityEmail = () => {
      let hasSecurityMail = localStorage.getItem(nameKeys.hasSecurityMail);
      if (typeof hasSecurityMail === 'string' && hasSecurityMail === 'false') {
        localStorage.removeItem(nameKeys.hasSecurityMail);
        Modal.confirm({
          title: vueI18n.t("common.hint"),
          content: vueI18n.t("common.security_email_remind"),
          okText: vueI18n.t("common.bind_it"),
          cancelText: vueI18n.t("common.cancel"),
          onOk: () => {
            router.push('/account/info?e=mail');
          }
        })
      }
    }
    
    onMounted(() => {
      startVersionCompare((callback) => {
        Modal.confirm({
          title: vueI18n.t("version.update_tips"),
          content: vueI18n.t("version.update_text"),
          okText: vueI18n.t("version.update_now"),
          cancelText: vueI18n.t("version.update_later"),
          onOk: () => {
            if (typeof callback === 'function') {
              callback(true)
            }
          },
          onCancel: () => {
            if (typeof callback === 'function') {
              callback(false)
            }
          }
        });
      })

      checkSecurityEmail();
    });

    return {
      ...toRefs(data),
      collapsed,
      closeSideBar,
      layout,
      isMobile,
      isHeaderMenu,
      appTheme,
      isLightSider,
      baseWidth,
      minBaseWidth,
      doZoom,
      doZoomOnResize,
    };
  },
});
</script>
<style lang="less">
#app-layout{
    height: 100%;
}
#app-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#app-layout .trigger:hover {
  color: #1890ff;
}
.site-layout .site-layout-background {
  background: #fff;
}
.layout-header.has-tabs{
  // height: 105px;
  height: auto;
}
#app-layout .ant-layout-sider .logo{
    width: 50px;
    height: 50px;
    margin: 20px auto;
}
.layout-mobile-mask{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9998;    
  background: rgba(0,0,0,.3);
}
.layout-mobile .ant-layout-sider{
  position: fixed;
  height: 100vh;
  z-index: 9999;
}
.layout-mobile .ant-layout-sider.ant-layout-sider-collapsed{
  width: 0!important;
  min-width: 0px!important;
  overflow: hidden;
}

#layout-content{
  margin: 24px 16px;
  padding: 24px;
  background: #fff; 
  min-Height: 280px;
  overflow:auto  
}
</style>