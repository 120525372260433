
import Layout from "@/views/layout/Index.vue";
export default {
    Layout,
    // "dashboard":()=>import("@/views/dashboard/Index.vue"),
    // "dashboard_index":()=>import("@/views/dashboard/Index.vue"),
    "dashboard_index":()=>import("@/views/overview/Overview.vue"),
    
    "account_info":()=>import("@/views/accounts/Info.vue"),
    "account_restpwd":()=>import("@/views/accounts/ResetPwd"),
    "account_announcement":()=>import("@/views/accounts/Announcement"),

    //产品管理
    "product_list": () => import("@/views/products/ProductList.vue"),
    "product_edit": () => import("@/views/products/Edit.vue"),
    "product_create": () => import("@/views/products/Create.vue"),
    "product_inventory": () => import("@/views/products/Inventory.vue"),

    //存储仓管理
    "storage_inventory": () => import("@/views/storage/Inventory.vue"),
    "storage_inbound_list": () => import("@/views/storage/inbound/List.vue"),
    "storage_inbound_create": () => import("@/views/storage/inbound/Create.vue"),
    "storage_inbound_confirm": () => import("@/views/storage/inbound/Confirm.vue"),
    "storage_inbound_shipping": () => import("@/views/storage/inbound/Shipping.vue"),
    "storage_inbound_detail": () => import("@/views/storage/inbound/Detail.vue"),

    "storage_out_plan": () => import("@/views/storage/outPlan/OutPlan.vue"),
    "storage_out_plan_details": () => import("@/views/storage/outPlan/OutPlanDetails.vue"),
    "storage_select_out_way": () => import("@/views/storage/outPlan/SelectOutWay.vue"),
    "storage_add_box_info": () => import("@/views/storage/outPlan/AddBoxInfo.vue"),
    "storage_confirm_out_plan": () => import("@/views/storage/outPlan/ConfirmOutPlan.vue"),
    "storage_label_change_list": () => import("@/views/storage/labelChange/List.vue"),
    "storage_label_change_detail": () => import("@/views/storage/labelChange/Detail.vue"),
    "storage_label_change_create": () => import("@/views/storage/labelChange/Create.vue"),
    "storage_label_change_uploadtags": () => import("@/views/storage/labelChange/UploadTags.vue"),
    "storage_label_change_confirm": () => import("@/views/storage/labelChange/Confirm.vue"),
    
    "consignment_return_channel_list": () => import("@/views/consignment/returnChannel/List.vue"),
    "consignment_return_channel_create": () => import("@/views/consignment/returnChannel/Create.vue"),
    "consignment_return_channel_detail": () => import("@/views/consignment/returnChannel/Detail.vue"),

    //配送仓管理 
    "consignment_inbound_list": () => import("@/views/consignment/inbound/List.vue"),
    "consignment_inbound_create": () => import("@/views/consignment/inbound/Create.vue"),
    "consignment_inbound_confirm": () => import("@/views/consignment/inbound/Confirm.vue"),
    "consignment_inbound_shipping": () => import("@/views/consignment/inbound/Shipping.vue"),
    "consignment_inbound_detail": () => import("@/views/consignment/inbound/Detail.vue"),
    "consignment_inventory": () => import("@/views/consignment/Inventory.vue"),

    "consignment_order_list": () => import("@/views/consignment/order/List.vue"),
    "consignment_order_create": () => import("@/views/consignment/order/Create.vue"),
    "consignment_order_add_product": () => import("@/views/consignment/order/AddProduct.vue"),
    "consignment_order_select_logistics": () => import("@/views/consignment/order/SelectLogistics.vue"),
    "consignment_order_details": () => import("@/views/consignment/order/Details.vue"),
    "consignment_order_bulk_import": () => import("@/views/consignment/order/BulkImport.vue"),

    "consignment_allot_plans":()=>import("@/views/consignment/allot/Plans.vue"),
    "consignment_allot_create":()=>import("@/views/consignment/allot/Create.vue"),
    "consignment_allot_set_box":()=>import("@/views/consignment/allot/SetBox.vue"),
    "consignment_allot_confirm":()=>import("@/views/consignment/allot/Confirm.vue"),
    "consignment_allot_plan_details":()=>import("@/views/consignment/allot/PlanDetails.vue"),
    "consignment_inventory_adjustment": () => import("@/views/consignment/InventoryAdjustment.vue"),
    "consignment_return_temporary_storage": () => import("@/views/consignment/ReturnTemporaryStorage.vue"),
    "consignment_return_temporary_storage_shop": () => import("@/views/consignment/ReturnTemporaryStorageShop.vue"),
    
    //运输管理
    "transport_plan": () => import("@/views/transport/plan/PlanList.vue"),
    "transport_create_plan": () => import("@/views/transport/plan/Create.vue"),
    "transport_set_plan_info": () => import("@/views/transport/plan/Set.vue"),
    "transport_confirm_plan": () => import("@/views/transport/plan/Confirm.vue"),
    "transport_plan_details": () => import("@/views/transport/plan/Details.vue"),

    "transport_appointment_list": () => import("@/views/transport/appointment/PlanList.vue"),
    "transport_appointment_create": () => import("@/views/transport/appointment/Create.vue"),
    "transport_appointment_details": () => import("@/views/transport/appointment/Details.vue"),
    "transport_import": () => import("@/views/transport/plan/Import.vue"),


    //财务
    "finance_inventory": () => import("@/views/finance/Inventory.vue"),
    "finance_details": () => import("@/views/finance/InventoryDetails.vue"),
    "finance_inventory_unsettled": () => import("@/views/finance/InventoryUnsettled.vue"),
    "finance_account_balance": () => import("@/views/finance/AccountBalance.vue"),
    "finance_bill": () => import("@/views/finance/Bill.vue"),
    "finance_recharge_record": () => import("@/views/finance/RechargeRecord.vue"),
    
    "inbound_survey": () => import("@/views/customer/InboundSurvey.vue"),

    "print_waybill_list": () => import("@/views/printWaybill/List.vue"),
    "print_waybill_create": () => import("@/views/printWaybill/Create.vue"),
    "print_waybill_confirm": () => import("@/views/printWaybill/Confirm.vue"),
    "print_waybill_details": () => import("@/views/printWaybill/Details.vue"),
    "print_waybill_batch_import": () => import("@/views/printWaybill/BatchImport.vue"),

    "authorization_manage_list": () => import("@/views/authorizationManage/List.vue"),

    //用户角色
    "user_role_list": () => import("@/views/user/RoleList.vue"),
    "user_account_list": () => import("@/views/user/account/List.vue"),
    "user_account_details": ()=>import("@/views/user/account/Details.vue"),
    "user_address": ()=>import("@/views/user/address/List.vue"),

    //中转仓预约入库
    "transit_in_plan_list": () => import("@/views/transit/inPlan/List.vue"),
    "transit_in_plan_create": () => import("@/views/transit/inPlan/Create.vue"),
    "transit_in_plan_add_shipment": () => import("@/views/transit/inPlan/AddShipment.vue"),
    "transit_in_plan_edit": () => import("@/views/transit/inPlan/Edit.vue"),
    "transit_in_plan_detail": () => import("@/views/transit/inPlan/Detail.vue"),
    //中转仓出库计划
    "transit_out_plan_list": () => import("@/views/transit/outPlan/List.vue"),
    "transit_out_plan_create": () => import("@/views/transit/outPlan/Create.vue"),
    "transit_out_plan_add_shipment": () => import("@/views/transit/outPlan/AddShipment.vue"),
    "transit_out_plan_confirm": () => import("@/views/transit/outPlan/Confirm.vue"),
    "transit_out_plan_detail": () => import("@/views/transit/outPlan/Detail.vue"),
    
    "transit_inventory": () => import("@/views/transit/Inventory.vue"),
    "transit_shipment": () => import("@/views/transit/Shipment.vue"),
    "transit_label_change_list": () => import("@/views/transit/labelChange/List.vue"),
    "transit_label_change_detail": () => import("@/views/transit/labelChange/Detail.vue"),
    "transit_label_change_create": () => import("@/views/transit/labelChange/Create.vue"),
    "transit_label_change_uploadtags": () => import("@/views/transit/labelChange/UploadTags.vue"),
    "transit_label_change_confirm": () => import("@/views/transit/labelChange/Confirm.vue"),

    "setting_notice": () => import("@/views/setting/Notice.vue"),
}